<template>
  <main class="p-4">
    <sqr-progress v-show="loading" />
    <div v-if="searchClient">
      <ais-instant-search index-name="policies" :search-client="searchClient">
        <div class="">
          <ais-search-box show-loading-indicator autofocus>
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
              <input
                class="form-input block w-full rounded-none rounded-l-md pl-4 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Rechercher dans les polices"
                type="search"
                :value="currentRefinement"
                @keydown.enter="refine($event.currentTarget.value)"
              />
              <span :hidden="!isSearchStalled">Loading...</span>
            </div>
            <div slot="submit-icon">
              <fa :icon="['far', 'search']" />
            </div>
            <div slot="reset-icon">
              <fa :icon="['far', 'times']" />
            </div>
          </ais-search-box>
          <div class="flex mt-4">
            <div
              class="flex-1 -my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            >
              <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
              >
                <ais-hits class="">
                  <table slot-scope="{ items }" class="min-w-full">
                    <thead>
                      <tr>
                        <th>Assureur</th>
                        <th>Branch</th>
                        <th>Numéro</th>
                        <th>Relation</th>
                        <th>Échéance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="cursor-pointer group"
                        v-for="item in items"
                        :key="item.objectID"
                      >
                        <td>
                          <ais-highlight :hit="item" attribute="insurerName" />
                        </td>
                        <td>
                          <ais-highlight :hit="item" attribute="branchAbrev" />
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'relation-policy',
                              params: {
                                bid: item.bid,
                                rid: item.relationId,
                                pid: item.objectID
                              }
                            }"
                          >
                            <ais-highlight
                              :hit="item"
                              attribute="number"
                              class="text-blue-600 font-medium"
                            />
                            {{item.number ? '' : 'N/A'}}
                          </router-link>
                        </td>
                        <td class="truncate">
                          <ais-highlight :hit="item" attribute="relationName"/>
                        </td>
                        <td>
                          {{item.dateEnd | date}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ais-hits>
              </div>
            </div>
            <div class="max-w-sm z-10 ml-4 hidden lg:block">
              <div class="p-2 bg-white rounded shadow">
                <ais-clear-refinements>
                  <span slot="resetLabel" class="text-sm font-semibold hover:text-blue-500">Effacer</span>
                </ais-clear-refinements>
                <h2 class="font-medium text-sm mt-2 ">Archivés</h2>
                <ais-toggle-refinement
                  attribute="archived"
                  label="Archivés"
                  :on="true"
                  :off="false"
                />
                <h2 class="font-medium text-sm mt-2">Assureurs</h2>
                <ais-refinement-list
                  attribute="insurerName"
                  :limit="5"
                  show-more
                >
                  <div slot="showMoreLabel" slot-scope="{ isShowingMore }">
                    {{ !isShowingMore ? 'plus…' : 'moins…' }}
                  </div>
                </ais-refinement-list>
                <h2 class="font-medium text-sm mt-2">Branches</h2>
                <ais-refinement-list
                  attribute="branchAbrev"
                  :limit="5"
                  show-more
                  showMoreLabel="plus…"
                >
                  <div slot="showMoreLabel" slot-scope="{ isShowingMore }">
                    {{ !isShowingMore ? 'plus…' : 'moins…' }}
                  </div>
                </ais-refinement-list>
                <h2 class="font-medium text-sm mt-2">Début</h2>
                <ais-range-input attribute="dateStartTs">
                  <form
                    slot-scope="{ currentRefinement, range, canRefine, refine }"
                  >
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.min)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.min, range.min)"
                      @input="
                        refine({
                          min: isoToTs($event.currentTarget.value),
                          max: currentRefinement.max || range.max
                        })
                      "
                    />
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.max)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.max, range.max)"
                      @input="
                        refine({
                          min: currentRefinement.min || rane.min,
                          max: isoToTs($event.currentTarget.value)
                        })
                      "
                    />
                  </form>
                </ais-range-input>
                <h2 class="font-medium text-sm mt-2">Échéance</h2>
                <ais-range-input attribute="dateEndTs">
                  <form
                    slot-scope="{ currentRefinement, range, canRefine, refine }"
                  >
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.min)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.min, range.min)"
                      @input="
                        refine({
                          min: isoToTs($event.currentTarget.value),
                          max: currentRefinement.max || range.max
                        })
                      "
                    />
                    <input
                      type="date"
                      :min="tsToIso(range.min)"
                      :max="tsToIso(range.max)"
                      :placeholder="tsToIso(range.max)"
                      :disabled="!canRefine"
                      :value="tsToIso(currentRefinement.max, range.max)"
                      @input="
                        refine({
                          min: currentRefinement.min || range.min,
                          max: isoToTs($event.currentTarget.value)
                        })
                      "
                    />
                  </form>
                </ais-range-input>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-4 py-3 flex items-center justify-between border-t border-gray-200"
        >
          <ais-pagination />
          <ais-hits-per-page
            :items="[
              { label: '10 par page', value: 10, default: true },
              { label: '20 par page', value: 20 }
            ]"
          />
        </div>
      </ais-instant-search>
    </div>
  </main>
</template>

<style lang="postcss" scoped>
th {
  @apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}
tr:nth-child(odd) {
  @apply bg-white;
}
tr:nth-child(even) {
  @apply bg-gray-50;
}
td {
  @apply px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-900;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import date from '@/filters/date';
import SqrProgress from '@/sqrd-ui/SqrProgress';

export default {
  name: 'SearchPolicies',
  components: { SqrProgress },
  filters: { date },
  props: { bid: String },
  computed: {
    ...mapState('search', ['loading']),
    ...mapGetters('search', ['searchClient'])
  },
  created() {
    const bid = this.bid;
    this.tokenGet({ bid });
  },
  methods: {
    ...mapActions('search', ['tokenGet']),
    tsToIso(value, limit) {
      return value && value !== limit
        ? DateTime.fromSeconds(value).toISODate()
        : '';
    },
    isoToTs(value, limit) {
      return value
        ? DateTime.fromISO(value, { zone: 'utc' }).toSeconds()
        : limit;
    }
  },
  metaInfo: {
    title: 'Recherche dans les polices'
  }
};
</script>
